<template>
    <div class="xcontainer">
        <div>
            <div class="align-items-center d-flex div-title-card justify-content-between row">
                <div class="align-items-baseline d-sm-flex flex-md-row flex-sm-column">
                    <h5 class="xtitle-buscar">Lista de anticipos a proveedores</h5>
                    <p class="ml-md-3 ml-sm-0 pt-md-0 pt-sm-1 xsubtitle-buscar">(Tabla principal)</p>
                </div>
                <a href="#" data-toggle="collapse" @click.prevent="togglePanel">
                    <i class="fa" :class="{ 'fa-chevron-down': !showPanel, 'fa-chevron-up': showPanel }"></i
                ></a>
            </div>

            <div :class="{ collapse: !showPanel }">
                <table :id="'list' + tableName">
                    <tr>
                        <td></td>
                    </tr>
                </table>
                <div :id="'pager' + tableName"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted } from "vue";
import jqgrid from "@/modules/providerAdvances/config/jqgrid";
import useAuth from "@/modules/auth/composables/useAuth";
import usePanel from "@/composables/usePanel";
import useMainTable from "@/composables/useMainTable";

export default {
    props: ["tableName"],
    setup(props) {
        const { companyId } = useAuth();
        const { showPanel, togglePanel } = usePanel();

        onMounted(() => {
            const body = window.$("body");
            const list = body.find("#list" + props.tableName);
            const pager = body.find("#pager" + props.tableName);

            const { onViewOptions, onShow, onChangeStatus, onStateButton } = useMainTable(props.tableName);

            onViewOptions("provider_advances/options_list");
            onShow("provider_advances.edit");
            onChangeStatus("provider_advances/status_list");
            onStateButton("provider_advances/status_list");

            list.jqGrid({
                ...jqgrid,
                pager,
                postData: {
                    filters: [{ field: "companyId", value: companyId.value }],
                },
            });
        });

        return {
            showPanel,
            togglePanel,
        };
    },
};
</script>
