import { baseURL } from "@/api/erpApi"

export default {
  mtype: "GET",
  datatype: "json",
  height: "auto",
  autowidth: true,
  rowList: [10, 20, 50, 100],
  rowNum: 10,
  page: 1,
  loadtext: "<p>Cargando...</p>",
  hoverrows: false,
  refresh: true,
  multiselect: true,
  url: baseURL + "/provider_advances",
  colNames: [
    "No. de anticipo",
    "Proveedor",
    "Monto total",
    "Saldo por pagar",
    "Estado",
    "",
    "",
  ],
  colModel: [
    { name: "code", width: 55 },
    { name: "providerName", width: 40 },
    { name: "amount", width: 40 },
    { name: "balanceTag", width: 40 },
    { name: "statusLabel", width: 40, align: "center" },
    { name: "options", width: 55, align: "center" },
    { name: "hiddenOptions", hidden: true },
  ],
  sortname: "createdAt",
  sortorder: "desc",
  viewrecords: true,
  gridview: true,
  loadBeforeSend: function (jqXHR: {
    setRequestHeader: (arg0: string, arg1: string | undefined) => void
  }) {
    jqXHR.setRequestHeader(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    )
  },
}
