import {ref} from "vue";

const usePanel = (defaultShowPanel = true) => {
    const showPanel = ref(defaultShowPanel)
    const togglePanel = () => {
        showPanel.value = !showPanel.value;
    }

    return {
        showPanel,
        togglePanel
    }
}

export default usePanel;
