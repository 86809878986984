import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchForm = _resolveComponent("SearchForm")!
  const _component_MainTable = _resolveComponent("MainTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SearchForm, { "table-name": $setup.tableName }, null, 8, ["table-name"]),
    _createVNode(_component_MainTable, { "table-name": $setup.tableName }, null, 8, ["table-name"])
  ], 64))
}