import toastr from "toastr";
import erpApi from "../api/erpApi";
import {useRouter} from "vue-router";

const useMainTable = (tableName) => {
    const router = useRouter()
    const body = window.$('body');
    const list = body.find("#list" + tableName);

    const onViewOptions = (url) => {
        body.off('click', '.viewOptions').on('click', '.viewOptions', function (e) {
            e.preventDefault();

            let id = window.$(this).data('id');
            let row = list.jqGrid('getRowData', id);

            const rowValue = () => row.code ? row.code : row.name;

            let modal = window.$('#optionsModal');
            modal.modal('show');

            modal.find('.modal-header').empty().html(`<h4>Opciones: ${rowValue()}</h4><small class="M-small-modal">Selecciona la acción que quieres realizar.</small>`);
            modal.find('.modal-body').empty().html(`
            <div class="M-load-options">
                <div ></div>
                <div ></div>
             </div>
            `);

            erpApi.get(url + '?' + window.$.param({id})).then((response) => {
                modal.find('.modal-body').empty().html(response.data);
            });
        });
    }

    const onShow = (routeName) => {
        body.off('click', '.showRow').on('click', '.showRow', function (e) {
            e.preventDefault();

            let modal = window.$('#optionsModal');
            modal.modal('hide');

            const id = window.$(this).attr("data-id");
            router.push({name: routeName, params: {id}})
        })
    }

    const onChangeStatus = (url, selector = '.changeStatus, #changeStatus') => {
        body.off('click', selector).on('click', selector, function (e) {
            e.preventDefault();

            let id = window.$(this).data('id');
            id = id ? id : list.jqGrid('getGridParam', 'selarrrow');

            if (window._.isArray(id) && window._.isEmpty(id)) {
                toastr.info('Seleccione por lo menos un elemento de la lista.');
                return;
            }

            let modal = window.$('#optionsModal');
            modal.modal('show');
            modal.find('.modal-header').empty().html(`<h4>Cambiar estado</h4>`);
            modal.find('.modal-body').empty().html(`
            <div class="M-load-options">
                <div ></div>
                <div ></div>
             </div>
    `);

            erpApi.get(url + '?' + window.$.param({id})).then((response) => {
                modal.find('.modal-body').empty().html(response.data);
            });
        });
    }

    const onStateButton = (url) => {
        body.off('click', '.state-btn').on('click', '.state-btn', function (e) {
            e.preventDefault()

            let id = window.$(this).data('id');
            const status = window.$(this).data('status');

            id = id ? id : list.jqGrid('getGridParam', 'selarrrow');

            if (window._.isArray(id) && window._.isEmpty(id)) {
                toastr.info('Seleccione por lo menos un elemento de la lista.');
                return;
            }

            let modal = window.$('#optionsModal');
            modal.find('.modal-body').empty().html(`
             <div class="M-load">
                <i class="fa fa-spinner" aria-hidden="true"></i> Cargando...
            </div>
            `);
            erpApi.put(url, {id, status}).then(() => {
                modal.modal('hide')
                toastr.success('Su solicitud se ha procesado correctamente.')
                list.trigger('reloadGrid');
            }).catch((error) => {
                console.error(error);
                toastr.error(error.response.data.message);
                list.trigger('reloadGrid');
            });
        });
    }

    return {
        onViewOptions,
        onShow,
        onChangeStatus,
        onStateButton
    }
}

export default useMainTable;
